<template>
  <div>
    <AppSpinnerBig :loading="loadingRef" />
    <AppCard>
      <template #header>
        <XeStepperCardHeader analytics-name="xe-send-money-delivery-open-payment" />
      </template>

      <AppCardImageTitle
        class="open-payment-title"
        alt="Globe"
        :title="title"
        :src="require(`@galileo/assets/images/illustrations/globe.svg`)"
      />

      <template #footer>
        <AppModalFooter class="open-payment-footer">
          <AppButton analytics-name="open-payment-modal-continue" @click="nextStep">
            {{ $t('ComponentOpenPaymentModal.ButtonContinue').value }}
          </AppButton>
          <AppButton
            analytics-name="open-payment-modal-continue"
            theme="secondary"
            @click="seeAvailableLocations"
          >
            {{ $t('ComponentOpenPaymentModal.ButtonLocations').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppCard>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { SEGMENT_EVENTS, SEGMENT_PAYOUT_AGENT_TYPE } from '@galileo/constants/segmentAnalytics'

import { useRouter } from '@galileo/composables/useRouter'

import {
  AppButton,
  AppCard,
  AppSpinnerBig,
  AppModalFooter,
  AppCardImageTitle,
} from '@oen.web.vue2/ui'

import {
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useAppStore,
  useTransactionStore,
} from '@galileo/stores'

export default {
  name: 'OpenPayment',
  emits: [],
  components: {
    AppButton,
    AppSpinnerBig,
    AppCard,
    AppCardImageTitle,
    AppModalFooter,
    XeStepperCardHeader,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const { getLocationUrl } = useTransactionStore()

    //TODO Refactor
    const title = computed(() => {
      let text = $t('ComponentOpenPaymentModal.TitleOpenPayment').value
      text = text.replace('{recipient}', truncateRecipientName(sendMoneyStore.getRecipientName, 30))
      const recipient = sendMoneyStore.getRecipient
      if (recipient) {
        text = text.replace('{city}', recipient.recipientCity ?? recipient.addressLine2)
      }
      return text
    })

    const truncateRecipientName = (str, num) => {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }

    const nextStep = () => {
      sendMoneyStore.goToNextStep()

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYOUT_LOCATION_SELECTED,
        traits: {
          payoutCountryType: SEGMENT_PAYOUT_AGENT_TYPE.OPEN_PAYMENT,
        },
      })
    }

    const loadingRef = ref(false)
    const seeAvailableLocations = async () => {
      const url = await getLocationUrl({
        country: sendMoneyStore.getCountryTo,
        currency: sendMoneyStore.form.currencyTo,
        cityName: sendMoneyStore.getRecipientCityName,
        stateName: await sendMoneyStore.getRecipientStateName(),
        agent: null,
        agentLocation: null,
        loadingRef,
      })
      appStore.openWindow(url)
    }

    return {
      title,
      nextStep,
      loadingRef,
      seeAvailableLocations,
      $t,
    }
  },
}
</script>

<style scoped>
.open-payment-title {
  ::v-deep h2 {
    font-size: 24px !important;
  }
}
.open-payment-footer {
  flex-direction: column-reverse;

  .button--secondary {
    @apply mt-4;
  }
}
</style>
